<template>
    <div v-if="posts.length > 0">
        <swiper :options="swiperNewsOption" ref="newsSwiper">
            <swiper-slide v-for="(post, index) in posts" :key="index">
                <div class="col">
                    <div class="image"><a :href="`/news/`+post.slug"><img :class="'lazyload' + ' ' +post.slug" :data-src="webp(post.better_featured_image?post.better_featured_image.media_details.sizes.medium.source_url:'img/no-image-available.png')" :alt="post.title.rendered"></a></div>
                    <div class="content">
                        <h3 class="title"><a :href="`/news/`+post.slug" v-html="post.title.rendered"></a></h3>
                        <p v-html="post.excerpt.rendered"></p>
                        <div class="date"><span class="bold" v-html="$t(`date`)"></span> {{ post.date | formatDate('DD/MM/YYYY') }}</div>
                    </div>
                </div>
            </swiper-slide>
        </swiper>

        <div class="navigation">
            <a href="" class="nav-previous"></a>
            <a href="" class="nav-next"></a>
        </div>
    </div>
</template>

<script>
    import 'swiper/dist/css/swiper.css'
    import { swiper, swiperSlide } from 'vue-awesome-swiper'
    import 'lazysizes'

    export default {
        name: "NewsSlide",
        props: {
            posts: Array
        },
        data() {
            return {
                lazyClass: 'lazyload',
                swiperNewsOption: {
                    slidesPerView: 'auto',
                    speed: 700,
                    navigation: {
                        prevEl: '.nav-previous',
                        nextEl: '.nav-next',
                        disabledClass: 'disable'
                    }
                },
            }
        },

        components : {
            swiper,
            swiperSlide
        },

        computed: {
            swiper() {
                return this.$refs.newsSwiper.swiper
            }
        }

    }
</script>
<style  lang="scss">
    .title {
        a {
            color: #000000;
        }
    }
</style>
