<template>
    <div class="section-related-news" data-scroll="4" id="news">
        <div class="container">
            <h2 class="section-related-news__title"><img :src="webp(require(`../../../public/img/news/related-news-title.png`))" alt="Tin tức liên quan"></h2>
            <div class="section-related-news__content">
                <div class="section-related-news__slide">
                    <NewsSlide :posts="related_posts"/>
                </div>
            </div><!--/ .section-news__content -->
        </div><!--/ container -->
    </div>
</template>

<script>
    import NewsSlide from "../corporate/NewsSlide";

    export default {
        name: "RelatedNews",
        props: {
            related_posts: Array
        },
        components : {
            NewsSlide
        }
    }
</script>

<style lang="scss">
    @import '../../assets/scss/mixin/_mixin';

    $sm: 768;
    $md: 992;
    $lg: 1200;

    .section-related-news {
        padding-top: 3rem;

        &__title {
            position: relative;
            padding-bottom: 1.2rem;
            padding-left: .5rem;
            margin-bottom: 3rem;
            @include media(set-min-w, $sm) {
                padding-left: 6.2rem;
                margin-bottom: 3.5rem;
            }
            @include media(set-min-w, $lg) {
                padding-left: 8.2rem;
            }

            &::after {
                content: '';
                position: absolute;
                right: 0;
                bottom: 0;
                left: 0;
                height: .5rem;
                background-color: #ffaf04;
                @include media(set-min-w, $sm) {
                    right: 1rem;
                    left: 6.2rem;
                }
                @include media(set-min-w, $lg) {
                    left: 8.2rem;
                }
            }

            img {
                max-height: 2.6rem;
                @include media(set-min-w, $sm) {
                    max-height: none;
                }
            }
        }

        &__slide {
            max-width: 48rem;
            margin: 0 auto;
            @include media(set-min-w, $sm) {
                max-width: none;
                padding-left: 5rem;
            }
            @include media(set-min-w, $lg) {
                padding-left: 7rem;
            }

            .swiper-container {
                &::before,
                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    display: none;
                    width: 1.2rem;
                    z-index: 9;
                    background-color: #fff;
                    @include media(set-min-w, $sm) {
                        display: block;
                    }
                }

                &::before {
                    left: 0;
                }

                &::after {
                    right: 0;
                }
            }

            .swiper-slide {
                @include media(set-min-w, $sm) {
                    width: 50%;
                }
                @include media(set-min-w, $md) {
                    width: 33.333333%;
                }
            }

            .col {
                margin-bottom: 35px;
                @include media(set-min-w, $sm) {
                    padding-right: 12px;
                    padding-left: 12px;
                    margin-bottom: 45px;
                }
            }

            .disable {
                opacity: .5;
            }
        }

        &__content {
            position: relative;
            padding: 0 1rem 2rem;
            line-height: 2.4rem;
            color: #6a6a6a;
            font-size: 1.6rem;
            @include media(set-min-w, $sm) {
                padding-right: 0;
                padding-bottom: 3rem;
                padding-left: 0;
            }

            .col {
                display: flex;
                @include media(set-min-w, $sm) {
                    display: block;
                }
            }

            .image {
                flex: 0 0 10rem;
                max-width: 10rem;
                text-align: center;
                @include media(set-min-w, $sm) {
                    max-width: none;
                }

                a {
                    display: flex;
                    height: 7rem;
                    @include media(set-min-w, $sm) {
                        height: 23rem;
                    }
                    @include media(set-min-w, 1600) {
                        height: 27.5rem;
                    }

                }

                img {
                    width: 100%;
                }
            }

            .content {
                flex-grow: 1;
                position: relative;
                display: flex;
                flex-direction: column-reverse;
                padding-left: 1.5rem;
                @include media(set-min-w, $sm) {
                    display: block;
                    padding-left: 0;
                }
            }

            .title {
                color: #000;
                font-size: 1.6rem;
                font-weight: 700;
                @include media(set-min-w, $sm) {
                    height: 5rem;
                    margin-top: 2.5rem;
                    margin-bottom: 1.5rem;
                    overflow: hidden;
                    font-size: 2.2rem;
                }
            }

            p {
                display: none;
                @include media(set-min-w, $sm) {
                    display: block;
                    height: 10rem;
                    margin-bottom: 2.5rem;
                    overflow: hidden;
                }
            }

            .date {
                display: flex;
                align-items: center;
                color: #000;
                font-size: 1.4rem;
                font-style: italic;

                img {
                    margin-right: 1rem;
                    @include media(set-min-w, $sm) {
                        display: none;
                    }
                }

                span {
                    display: none;
                    margin-right: .5rem;
                    @include media(set-min-w, $sm) {
                        display: inline-block;
                    }
                }
            }

            .bold {
                font-weight: 700;
                font-style: normal;
            }

            .navigation {
                position: absolute;
                top: 67px;
                left: 0;
                display: none;
                z-index: 9;

                @include media(set-min-w, $sm) {
                    display: block;
                }

                a {
                    display: block;
                    width: 4.2rem;
                    height: 4.2rem;
                    margin: 1rem auto;
                    background-repeat: no-repeat;
                    background-position: center center;
                    border: 1px solid #cfcfcf;
                    border-radius: 50%;

                    &:active,
                    &:focus {
                        outline: none;
                    }

                    &:hover {
                        border-color: #000;
                    }

                    @include media(set-min-w, $lg) {
                        width: 6.2rem;
                        height: 6.2rem;
                    }
                }

                .nav-previous {
                    background-image: url("../../../public/img/icon/icon-arrow-half.png");
                }

                .nav-next {
                    transform: rotateY(-180deg);
                    background-image: url("../../../public/img/icon/icon-arrow-half.png");
                }
            }
        }
    }

</style>